<template>
    <div class="webinarsTable">
        <div class="webinarsTable__row webinarsTable__row--head">
            <div class="webinarsTable__col webinarsTable__col--1">
                <p class="text2 colorFiord">{{ thead[0] }}</p>
            </div>
            <div class="webinarsTable__col webinarsTable__col--2">
                <p class="text2 colorFiord">{{ thead[1] }}</p>
            </div>
            <div class="webinarsTable__col webinarsTable__col--3">
                <p class="text2 colorFiord">{{ thead[2] }}</p>
            </div>
            <div v-if="type === 'live'" class="webinarsTable__col webinarsTable__col--4">
                <p class="text2 colorFiord">
                    {{ thead[3] }}
                    <Tooltip
                        class="webinars__content_passwordImg"
                        :tooltipIcon="require('@/assets/img/common/info.svg')"
                        text='Пароль нужен для входа на трансляцию. Скопируйте его и вставьте в следующем окне после нажатия на кпопку "Подключиться" в разделе "Доступ"'
                    />
                </p>
            </div>

            <div class="webinarsTable__col webinarsTable__col--5">
                <p class="text2 colorFiord">
                    {{ thead[4] }}
                </p>
            </div>
            <div class="webinarsTable__col webinarsTable__col--6">
                <p class="text2 colorFiord">{{ thead[5] }}</p>
            </div>
        </div>

        <WebinarsRow
            v-for="(row, index) in tbody"
            :key="index"
            :type="type"
            :row="row"
            :onClick="() => onClick('RECORDS')"
        />
    </div>
</template>

<script>
import WebinarsRow from "@/views/components/WebinarsRow/WebinarsRow";
import Tooltip from "@/views/components/Tooltip/Tooltip";

export default {
    name: "WebinarsTable",
    components: {
        Tooltip,
        WebinarsRow,
    },

    props: {
        type: String,
        onConnectClick: Function,
        thead: Array,
        tbody: [{
            datetime: String,
            time: String,
            isLive: Boolean,
            title: String,
            description: String,
            password: String,
            startsIn: String,
            hasAccess: Boolean,
            priority: Number
        }],
    },
    methods: {
        onClick(value) {
            this.onConnectClick(value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "webinarsTable.scss";
</style>
