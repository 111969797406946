export function getDateFromDatetime(datetime) {
    const date = new Date(datetime);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (Number(day) < 10) {
        day = "0" + day.toString();
    }
    if (Number(month) < 10) {
        month = "0" + month.toString();
    }

    return day + '.' + month + '.' + year;
}

export function getTimeFromDatetime(datetime, change) {
    const date = new Date(datetime);

    let hours = date.getHours();
    if(change) {
        hours = hours - 1
    }
    let minutes = date.getMinutes();

    if (Number(hours) < 10) {
        hours = "0" + hours.toString();
    }
    if (Number(minutes) < 10) {
        minutes = "0" + minutes.toString();
    }

    return hours + ':' + minutes;
}