<template>
    <Layout>
        <div class="pageSection">
            <SideBar />

            <div class="pageContent pageContent--3 webinars">
                <p class="pageTitle">Вебинары</p>

                <div class="webinarsContentHeader">
                    <div
                        class="popup-layout"
                        v-if="
                            isVisibleModalWebinarsOnboarding ||
                                this.$store.getters.getIsReviewOnboardWebinars
                                    .isReviewOnboardWebinars
                        "
                    ></div>
                    <div
                        :class="
                            `webinarsButtons ${
                                (isVisibleModalWebinarsOnboarding &&
                                    isVisibleWebinarsOnboarding) ||
                                (this.$store.getters.getIsReviewOnboardWebinars
                                    .isReviewOnboardWebinars &&
                                    isVisibleWebinarsOnboarding)
                                    ? 'webinarOnboarding'
                                    : ''
                            }`
                        "
                    >
                        <OnboardModal
                            v-if="
                                (isVisibleModalWebinarsOnboarding &&
                                    isVisibleWebinarsOnboarding) ||
                                    (this.$store.getters.getIsReviewOnboardWebinars
                                        .isReviewOnboardWebinars &&
                                        isVisibleWebinarsOnboarding)
                            "
                            title='Раздел "Вебинары"'
                            description="В этом разделе вы можете подключиться к прямым трансляциям или узнать их расписание, а также посмотреть вебинары в записи."
                            :isNextButton="true"
                            nextButtonTitle="ЗАВЕРШИТЬ"
                            :onClose="() => showedWebinarsOnboarding()"
                            :onCloseModal="() => showedWebinarsOnboarding()"
                        />
                        <b-skeleton
                            v-if="isLoadingWebinarsData"
                            width="130px"
                            type="button"
                        ></b-skeleton>
                        <b-skeleton
                            v-if="isLoadingWebinarsData"
                            width="175px"
                            type="button"
                            style="margin-left: 12px"
                        ></b-skeleton>
                        <Button
                            v-if="!isLoadingWebinarsData"
                            class="webinarsButtons__button"
                            :extraClass="
                                schedule
                                    ? `button--small`
                                    : `button--small button--smallWhite`
                            "
                            title="Расписание"
                            :onClick="() => handleClickRadioButton('SCHEDULE')"
                        />
                        <Button
                            v-if="!isLoadingWebinarsData"
                            class="webinarsButtons__button"
                            :extraClass="
                                records
                                    ? `button--small`
                                    : `button--small button--smallWhite`
                            "
                            title="Записи вебинаров"
                            :onClick="() => handleClickRadioButton('RECORDS')"
                        />
                    </div>
                    <Button title="предложить тему" :onClick="() => onOpenModal()" />
                    <SuggestTheme v-if="isVisibleModal" :onClose="() => onCloseModal()" />
                </div>
                <b-skeleton-table
                    v-if="isLoadingWebinarsData"
                    :rows="3"
                    :columns="6"
                    :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>

                <div class="webinars__content" v-else>
                    <div :class="radioButton ? 'hidden' : ''">
                        <WebinarsTable
                            :onConnectClick="(value) => handleClickConnect(value)"
                            :type="liveTable.type"
                            :thead="liveTable.thead"
                            :tbody="this.$store.getters.getSchedule"
                        />
                    </div>

                    <div :class="radioButton ? '' : 'hidden'">
                        <WebinarsTable
                            :onConnectClick="(value) => handleClickConnect(value)"
                            :type="recordTable.type"
                            :thead="recordTable.thead"
                            :tbody="webinarsData"
                        />
                    </div>

                    <!--<p class="h2 colorBotticelli webinars__content_noItems">-->
                    <!--На данный момент запланированных вебинаров нет-->
                    <!--</p>-->
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import SideBar from "@/views/partials/sideBar/sideBar";
import Button from "@/views/components/Button/Button";
import WebinarsTable from "@/views/components/WebinarsTable/WebinarsTable";

import SuggestTheme from "@/views/pages/webinars/suggestTheme/suggestTheme";
import OnboardModal from "@/views/components/OnboardModal/OnboardModal";

export default {
    name: "webinars",
    components: {
        Layout,
        SideBar,
        Button,
        SuggestTheme,
        WebinarsTable,
        OnboardModal,
    },

    async beforeMount() {
        if (
            !(
                this.$store.getters.getMember &&
                this.$store.getters.getMember.data &&
                this.$store.getters.getMember.data.enabled &&
                this.$store.getters.getMember.data.enabled.webinars &&
                this.$store.getters.getMember.data.enabled.webinars.enabled
            )
        ) {
            await this.$router.push("/" + this.$route.params.slug);
        }

        this.updateScheduleStore();
        this.updateWebinarsStore();
        // this.updateMemberStore();
        // this.updateMemberConfirmationsStore();
    },

    watch: {
        "$route.params.slug": function() {
            this.updateScheduleStore();
            this.updateWebinarsStore();
            // this.updateMemberStore();
            // this.updateMemberConfirmationsStore();
        },
    },

    computed: {
        webinarsData() {
            return this.$store.getters.getWebinars;
        },

        member: function() {
            return (
                (this.$store.getters.getMember && this.$store.getters.getMember.data) ||
                {}
            );
        },

        confirmations: function() {
            return (
                (this.$store.getters.getConfirmations &&
                    this.$store.getters.getConfirmations.data) ||
                {}
            );
        },

        isVisibleModalWebinarsOnboarding: function() {
            return (
                // this.isLoadedConfirmations &&
                this.confirmations &&
                this.confirmations.viewedWelcomePopup &&
                !(this.member.agreementData && this.member.agreementData.showRules) &&
                this.confirmations.viewedWelcomeVideo &&
                !this.confirmations.viewedOnBoardingWebinars &&
                this.$route.path === "/trejding_ot_a_do_ya_3_5/webinars"
            );
        },
    },

    data() {
        return {
            title: "Вебинары",
            isVisibleModal: false,
            isVisibleModalAccess: false,
            radioButton: false,
            schedule: true,
            records: false,

            isLoadedConfirmations: false,
            isVisibleWebinarsOnboarding: true,

            isLoadingWebinarsData: false,

            isReviewOnboardWebinars: this.$store.getters.getIsReviewOnboardWebinars
                .isReviewOnboardWebinars,

            liveTable: {
                type: "live",
                thead: ["Дата/Время", "Куратор", "Тема", "Пароль", "Статус", "Доступ"],
                tbody: [
                    {
                        date: "14 июня",
                        time: "",
                        isLive: true,
                        curator: "Роман Щеглов",
                        theme: "Фазы рынка",
                        password: "2509218",
                        startsIn: "",
                        isRestrictedForVip: false,
                    },
                ],
            },

            recordTable: {
                type: "record",
                thead: ["Дата/Время", "Куратор", "Тема", "Пароль", "Статус", "Доступ"],
                tbody: [
                    {
                        date: "14 июня",
                        time: "17:00-19:00 (UTC +3)",
                        isLive: false,
                        curator: "Роман Щеглов",
                        theme: "Фазы рынка",
                        password: "",
                        startsIn: "",
                        isRestrictedForVip: false,
                    },
                ],
            },
        };
    },
    methods: {
        onOpenModal() {
            this.isVisibleModal = true;
        },
        onCloseModal() {
            this.isVisibleModal = false;
        },

        handleClickRadioButton(value) {
            if (value === "SCHEDULE") {
                this.schedule = true;
                this.records = false;
                this.radioButton = false;
            }

            if (value === "RECORDS") {
                this.schedule = false;
                this.records = true;
                this.radioButton = true;
            }
        },

        handleClickConnect(value) {
            if (value === "RECORDS") {
                this.schedule = false;
                this.records = true;
                this.radioButton = true;
            }
        },

        async updateMemberStore() {
            const getMemberData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study"
            );
            if (getMemberData && getMemberData.data && getMemberData.data.result) {
                if (
                    getMemberData.data.code === "programs" ||
                    getMemberData.data.code === "banned" ||
                    getMemberData.data.code === "blocked"
                ) {
                    await this.$store.dispatch("clearMember");
                    await this.$router.push("/");
                } else {
                    await this.$store.dispatch("setMember", {
                        data: getMemberData.data,
                    });
                }
            } else {
                console.error(getMemberData.data.error);
                await this.$store.dispatch("clearMember");
                await this.$router.push("/");
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            getMemberData.data.error === "Пользователь не найден"
                                ? "В момент заморозки личный кабинет недоступен, но после ее окончания Вы с лёгкостью сможете вернуться в кабинет и продолжить обучение"
                                : getMemberData.data.error,
                    },
                });
            }
        },

        async updateMemberConfirmationsStore() {
            const getMemberConfirmationsData = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/confirmations"
            );
            if (
                getMemberConfirmationsData &&
                getMemberConfirmationsData.data &&
                getMemberConfirmationsData.data.result
            ) {
                await this.$store.dispatch("setConfirmations", {
                    data: getMemberConfirmationsData.data.confirmations,
                });

                this.isLoadedConfirmations = true;
            } else {
                console.error(
                    (getMemberConfirmationsData.data &&
                        getMemberConfirmationsData.data.message) ||
                        "Ошибка при поиске статусов ознакомительных попапов"
                );
                await this.$store.dispatch("clearConfirmations");
            }
        },

        async showedWebinarsOnboarding() {
            if (
                this.isVisibleModalWebinarsOnboarding &&
                this.$store.getters.getIsReviewOnboardWebinars.isReviewOnboardWebinars
            ) {
                await this.axios.post(
                    "/programs/" +
                        this.$route.params.slug +
                        "/confirmations?confirmation=viewedOnBoardingWebinars"
                );
                this.updateMemberStore();
                this.updateMemberConfirmationsStore();
                this.isVisibleWebinarsOnboarding = false;
                this.$store.dispatch("setIsReviewOnBoardWebinars", false);
                await this.$router.push("/" + this.$route.params.slug + "/bonuses");
                this.$store.dispatch("setIsReviewOnBoardBonuses", true);
            } else {
                if (this.isVisibleModalWebinarsOnboarding) {
                    await this.axios.post(
                        "/programs/" +
                            this.$route.params.slug +
                            "/confirmations?confirmation=viewedOnBoardingWebinars"
                    );
                    this.updateMemberStore();
                    this.updateMemberConfirmationsStore();
                    this.isVisibleWebinarsOnboarding = false;
                } else {
                    this.$store.dispatch("setIsReviewOnBoardWebinars", false);
                    await this.$router.push("/" + this.$route.params.slug + "/bonuses");
                    this.$store.dispatch("setIsReviewOnBoardBonuses", true);
                }
            }
        },

        async updateScheduleStore() {
            this.isLoadingWebinarsData = true;
            const getScheduleResult = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/schedule/v2"
            );

            if (
                getScheduleResult &&
                getScheduleResult.data &&
                getScheduleResult.data.result
            ) {
                await this.$store.dispatch("setSchedule", getScheduleResult.data);
            } else {
                console.error(
                    "Ошибка при поиске расписания: " + getScheduleResult.data.error
                );
                await this.$store.dispatch("clearSchedule");
            }

            this.isLoadingWebinarsData = false;
        },

        async updateWebinarsStore() {
            const getWebinarsResult = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/webinars/v2"
            );

            if (
                getWebinarsResult &&
                getWebinarsResult.data &&
                getWebinarsResult.data.result
            ) {
                await this.$store.dispatch("setWebinars", getWebinarsResult.data);
            } else {
                console.error(
                    "Ошибка при поиске вебинаров: " + getWebinarsResult.data.error
                );
                await this.$store.dispatch("clearWebinars");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "webinars.scss";
</style>
