<template>
    <div class="webinarsTable__row">
        <div class="webinarsTable__col webinarsTable__col--1">
            <p v-if="this.date" class="text2">{{ date }}</p>
            <p v-if="row.isLive" class="text3 colorRed">LIVE</p>
            <p v-else-if="this.time" class="h5 colorGray">{{ time }}</p>
        </div>
        <div class="webinarsTable__col webinarsTable__col--2">
            <p v-if="row.curator" class="text2">{{ row.curator }}</p>
        </div>
        <div class="webinarsTable__col webinarsTable__col--3">
            <p v-if="row.theme" class="text2">{{ row.theme }}</p>
        </div>
        <div v-if="type === 'live'" class="webinarsTable__col webinarsTable__col--4">
            <div class="webinarsPassword">
                <button
                    type="button"
                    v-clipboard:copy="row.password"
                    v-clipboard:success="onCopySuccess"
                    v-clipboard:error="onCopyError"
                    class="text2 colorGray webinarsPassword__btn"
                >
                    {{ row.password }}
                    <span class="webinarsPassword__btn_imgWrapper">
                        <img
                            src="@/assets/img/pages/webinars/copy.svg"
                            :class="
                                showCopySuccess
                                    ? 'webinarsPassword__btn_img webinarsPassword__btn_img--visible'
                                    : 'webinarsPassword__btn_img'
                            "
                            class="webinarsPassword__btn_img"
                        />
                        <span
                            :class="
                                showCopySuccess
                                    ? 'tooltipBox h5 webinarsPassword__btn_success tooltipBox--visible'
                                    : 'tooltipBox h5 webinarsPassword__btn_success'
                            "
                        >
                            Скопировано
                        </span>
                    </span>
                </button>
            </div>
        </div>

        <div class="webinarsTable__col webinarsTable__col--5">
            <div class="webinarsStatus">
                <p class="text2 webinarsStatus__info" v-if="row.status === 'inProgress'">
                    В процессе
                    <img
                        class="webinarsStatus__icon"
                        src="@/assets/img/common/pause.svg"
                        alt="progress"
                    />
                </p>

                <p class="text2 webinarsStatus__info" v-if="row.status === 'isWatched'">
                    Просмотрено
                    <img
                        class="webinarsStatus__icon"
                        src="@/assets/img/common/iconCheckSuccess.svg"
                        alt="watched"
                    />
                </p>
            </div>
        </div>

        <div class="webinarsTable__col webinarsTable__col--6">
            <VipUpsellButton v-if="!row.hasAccess" />
            <p v-else-if="row.daysToStart === 1" class="text2 colorGray">
                Начнется через {{ row.daysToStart }} день
            </p>
            <p v-else-if="row.daysToStart >= 2 && row.daysToStart <= 4" class="text2 colorGray">
                Начнется через {{ row.daysToStart }} дня
            </p>
            <p v-else-if="row.daysToStart >= 5" class="text2 colorGray">
                Начнется через {{ row.daysToStart }} дней
            </p>
            <span
                class="text3 colorDenim"
                style="cursor: pointer"
                v-else-if="row.shouldRedirectToWebinars"
                @click="() => onClick()"
            >
                подключиться
            </span>
            <router-link
                :to="
                    type === 'live'
                        ? `/${this.$route.params.slug}/webinars/live`
                        : `/${this.$route.params.slug}/webinars/recording/${row.priority}`
                "
                v-else
                class="text3 colorDenim"
            >
<!--                <span v-if="type === 'live'" @click="() => setSelectedWebinar(row)">подключиться</span>-->
<!--                <span v-else @click="() => setSelectedWebinar(row)">смотреть</span>-->

                <span v-if="type === 'live'">подключиться</span>
                <span v-else>смотреть</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import VipUpsellButton from "@/views/components/VipUpsellButton/VipUpsellButton";

import { getDateFromDatetime, getTimeFromDatetime } from "../../../helpers/dateFormat";

export default {
    name: "webinarsRow",

    components: {
        VipUpsellButton,
    },

    computed: {
        date() {
            return getDateFromDatetime(this.row.datetime);
        },
        time() {
            return getTimeFromDatetime(this.row.datetime, true);
        },
    },

    beforeMount() {},

    data() {
        return {
            showCopySuccess: false,
        };
    },

    props: {
        type: String,
        onClick: Function,
        row: {
            datetime: String,
            time: String,
            isLive: Boolean,
            title: String,
            description: String,
            password: String,
            startsIn: String,
            hasAccess: Boolean,
            videos: Array,
            priority: Number
        },
    },

    methods: {
        onClickButton(event) {
            this.$emit("clicked", "RECORDS");
        },
        onCopySuccess: function(e) {
            this.showCopySuccess = true;
            setTimeout(() => (this.showCopySuccess = false), 2000);
        },
        onCopyError: function(e) {
            alert("Проблема при копировании пароля.");
        },

        // setSelectedWebinar: async function(item) {
        //     this.$store.dispatch("setSelectedWebinar", item);
        //     if (!item.status && !item.password) {
        //         await this.axios.get(
        //             `/programs/${this.$route.params.slug}/webinars/${item._id}/inProgress`
        //         );
        //     }
        //     if (!item.status && item.password) {
        //         await this.axios.get(
        //             `/programs/${this.$route.params.slug}/schedule/${item._id}/inProgress`
        //         );
        //     }
        // },
    },
};
</script>

<style lang="scss" scoped>
@import "webinarsRow.scss";
</style>
