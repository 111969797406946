<template>
    <div class="popup-layout suggestTheme">
        <div class="popup popup--2">
            <button type="button" class="popup__close" @click="onClose"></button>
            <p class="h2 popup__title suggestTheme__title">
                Предложить тему для вебинара
            </p>
            <div class="form">
                <div class="login__input formgroup">
                    <label class="formgroup__label">
                        Тема
                    </label>
                    <Input
                        type="text"
                        placeholder="Введите текст"
                        :inputValue="theme"
                        :isError="isEmptyTheme"
                        errorText="Введите тему"
                        :onChange="(e) => onChangeThemeInput(e)"
                    />
                </div>
                <div class="login__input formgroup">
                    <label class="formgroup__label">
                        Описание
                    </label>
                    <div>
                        <TextArea
                            class="suggestTheme__inputWrapper"
                            rows="7"
                            placeholder="Что Вас интересует и о чем Вам будет интересно узнать?"
                            :isError="isEmptyDescription || isDescriptionLong"
                            :errorText="
                                isEmptyDescription
                                    ? 'Введите описание'
                                    : 'Вы не можете отправить более 1000 символов в этом текстовом поле'
                            "
                            :optimalTextLength="200"
                            optimalLengthTooltip="Постарайтесь аргументировать свой ответ, но уместить его в 200 символов, для того, чтобы другим студентам было комфортно его читать."
                            :onChange="(e) => onChangeTextArea(e)"
                        />
                    </div>
                </div>
                <div class="login__button form__submit">
                    <Button
                        title="Отправить"
                        extraClass="button"
                        :onClick="() => onValidateInput()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Input from "@/views/components/Input/Input";
import TextArea from "@/views/components/TextArea/TextArea";
import Button from "@/views/components/Button/Button";
export default {
    name: "suggestTheme",
    components: { Input, TextArea, Button },
    props: ["onClose"],
    data() {
        return {
            theme: "",
            description: "",
            isEmptyTheme: false,
            isDescriptionLong: false,
            isEmptyDescription: false,
        };
    },

    methods: {
        onChangeThemeInput(value) {
            this.theme = value;
        },
        onChangeTextArea(value) {
            this.description = value;
        },
        onValidateInput() {
            this.isEmptyTheme = !this.theme;
            this.isEmptyDescription = !this.description;
            this.isDescriptionLong = this.description.length > 1000;

            if (this.theme !== "" && this.description && this.description.length < 1000) {
                const data = {
                    theme: this.theme,
                    description: this.description,
                };
                this.suggestTheme();
            }
        },

        suggestTheme: async function() {
            const chatBody = {
                type: "webinar",
                theme: this.theme,
                text: this.description,
                user: this.$store.getters.getMember.data.user._id,
            };
            const response = await this.axios.post(
                `/programs/${this.$route.params.slug}/schedule/webinar_suggestion`,
                chatBody
            );
            if (response && response.data && response.data.result) {
                this.onClose();
                if (response.data.status !== "limit") {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "success",
                            text:
                                "Вы успешно отправили предложение на тему вебинаров. Спасибо за Ваше небезраличие.",
                        },
                    });
                } else {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: response.data.message,
                        },
                    });
                }
            } else {
                this.onClose();
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text:
                            "Отправить предложение на тему вебинаров не удалось. Напишите Ваше предложение в поддержку.",
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "suggestTheme.scss";
</style>
