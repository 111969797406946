var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"pageSection"},[_c('SideBar'),_c('div',{staticClass:"pageContent pageContent--3 webinars"},[_c('p',{staticClass:"pageTitle"},[_vm._v("Вебинары")]),_c('div',{staticClass:"webinarsContentHeader"},[(
                        _vm.isVisibleModalWebinarsOnboarding ||
                            this.$store.getters.getIsReviewOnboardWebinars
                                .isReviewOnboardWebinars
                    )?_c('div',{staticClass:"popup-layout"}):_vm._e(),_c('div',{class:("webinarsButtons " + ((_vm.isVisibleModalWebinarsOnboarding &&
                                _vm.isVisibleWebinarsOnboarding) ||
                            (this.$store.getters.getIsReviewOnboardWebinars
                                .isReviewOnboardWebinars &&
                                _vm.isVisibleWebinarsOnboarding)
                                ? 'webinarOnboarding'
                                : ''))},[(
                            (_vm.isVisibleModalWebinarsOnboarding &&
                                _vm.isVisibleWebinarsOnboarding) ||
                                (this.$store.getters.getIsReviewOnboardWebinars
                                    .isReviewOnboardWebinars &&
                                    _vm.isVisibleWebinarsOnboarding)
                        )?_c('OnboardModal',{attrs:{"title":"Раздел \"Вебинары\"","description":"В этом разделе вы можете подключиться к прямым трансляциям или узнать их расписание, а также посмотреть вебинары в записи.","isNextButton":true,"nextButtonTitle":"ЗАВЕРШИТЬ","onClose":function () { return _vm.showedWebinarsOnboarding(); },"onCloseModal":function () { return _vm.showedWebinarsOnboarding(); }}}):_vm._e(),(_vm.isLoadingWebinarsData)?_c('b-skeleton',{attrs:{"width":"130px","type":"button"}}):_vm._e(),(_vm.isLoadingWebinarsData)?_c('b-skeleton',{staticStyle:{"margin-left":"12px"},attrs:{"width":"175px","type":"button"}}):_vm._e(),(!_vm.isLoadingWebinarsData)?_c('Button',{staticClass:"webinarsButtons__button",attrs:{"extraClass":_vm.schedule
                                ? "button--small"
                                : "button--small button--smallWhite","title":"Расписание","onClick":function () { return _vm.handleClickRadioButton('SCHEDULE'); }}}):_vm._e(),(!_vm.isLoadingWebinarsData)?_c('Button',{staticClass:"webinarsButtons__button",attrs:{"extraClass":_vm.records
                                ? "button--small"
                                : "button--small button--smallWhite","title":"Записи вебинаров","onClick":function () { return _vm.handleClickRadioButton('RECORDS'); }}}):_vm._e()],1),_c('Button',{attrs:{"title":"предложить тему","onClick":function () { return _vm.onOpenModal(); }}}),(_vm.isVisibleModal)?_c('SuggestTheme',{attrs:{"onClose":function () { return _vm.onCloseModal(); }}}):_vm._e()],1),(_vm.isLoadingWebinarsData)?_c('b-skeleton-table',{attrs:{"rows":3,"columns":6,"table-props":{ bordered: true, striped: true }}}):_c('div',{staticClass:"webinars__content"},[_c('div',{class:_vm.radioButton ? 'hidden' : ''},[_c('WebinarsTable',{attrs:{"onConnectClick":function (value) { return _vm.handleClickConnect(value); },"type":_vm.liveTable.type,"thead":_vm.liveTable.thead,"tbody":this.$store.getters.getSchedule}})],1),_c('div',{class:_vm.radioButton ? '' : 'hidden'},[_c('WebinarsTable',{attrs:{"onConnectClick":function (value) { return _vm.handleClickConnect(value); },"type":_vm.recordTable.type,"thead":_vm.recordTable.thead,"tbody":_vm.webinarsData}})],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }